import { constants } from "./index";
import { toast } from 'react-toastify';

class FormMessage{

    constructor(){
        
    }

    showError(message){
        toast(message, {type: constants.TOAST_TYPES.WARNING})
    }

    showSuccess(message){
        toast(message, {type: constants.TOAST_TYPES.WARNING});
    }

}

export default FormMessage;