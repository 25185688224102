import './App.css';
import { useState } from 'react';
import {
    Outlet,
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import { constants, Storage, ScrollToTop } from "./app/utils";
import { SignIn, SignUp, Home, Landing, CodeShare, ChatExpert, AboutUs, ContactUs, TermsConditions, PrivacyPolicy, Pricing } from "./app/views";
import { Header, Footer, SidebarRight, Error, SidebarLeft } from "./app/components";
import { PromptProvider } from './app/context/prompt';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';

const storage = new Storage();
const queryClient = new QueryClient();

const AppLayout = () => {

    return (
        <div className="app">
            <ScrollToTop/>
            <Header />
            {storage.isUserExists() ? <Navigate to={constants.ROUTES.LANDING}  replace />: <Outlet/> }
            <ToastContainer />
        </div>
    );

};


const AppHomeLayout = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    return (
        <QueryClientProvider client={queryClient}>
            <PromptProvider>
                <div className="app">
                    <ScrollToTop/>
                    <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
                    <SidebarLeft/>
                    {storage.isUserExists() ? <Outlet context={[isHistoryOpen, setIsHistoryOpen]} />: <Navigate to={constants.ROUTES.LANDING}  replace /> }
                    <SidebarRight isHistoryOpen={isHistoryOpen} setIsHistoryOpen={setIsHistoryOpen} />
                    <ToastContainer />
                </div>
            </PromptProvider>
        </QueryClientProvider>
    );

}

const AllLayout = () => {

    return (<div className="app">
        <ScrollToTop/>
        <Header/>
        <Outlet/>
        <ToastContainer />
        <Footer/>
    </div>)

}

const router = createBrowserRouter([
    {
        path: constants.ROUTES.HOME,
        element: <AppHomeLayout/>,
        errorElement: <Error/>,
        children: [
            {
                path: constants.ROUTES.HOME,
                element: <Home />,
                canActivate: true
            },
            {
                path: constants.ROUTES.CHAT_EXPERT,
                element: <ChatExpert />
            },
            {
                path: constants.ROUTES.CODE_SHARE,
                element: <CodeShare/>
            }
        ]
    },
    {
        path: constants.ROUTES.LANDING,
        element: <AllLayout/>,
        children: [
            {
                path: constants.ROUTES.LANDING,
                element: <Landing />
            },
            {
                path: constants.ROUTES.ABOUT_US,
                element: <AboutUs />
            },
            {
                path: constants.ROUTES.CONTACT_US,
                element: <ContactUs />
            },
            {
                path: constants.ROUTES.TERMS_CONDITIONS,
                element: <TermsConditions/>
            },
            {
                path: constants.ROUTES.PRIVACY_POLICY,
                element: <PrivacyPolicy/>
            },
            {
                path: constants.ROUTES.PRICING,
                element: <Pricing/>
            }
        ]
    },
    {
        path: '/',
        element: <AppLayout/>,
        children: [
            {
                path: constants.ROUTES.SIGN_IN,
                element: <SignIn />
            },
            {
                path: constants.ROUTES.SIGN_UP,
                element: <SignUp />
            }
        ]
    }
]);

function App() {

    return (
        <RouterProvider router={router} />
    );

}

export default App;
