import { useState, useEffect } from "react";
import { constants } from "../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCopy, faCheck, faTimes);

export default function CopyToClipboard({ text }) {

    const [copySuccess, setCopySuccess] = useState(constants.COPY_CLIPBOARD_STATES.NOT_COPIED);

    useEffect(() => {

        //Make the copy success state back to the initial state so that in case
        //user needs to copy again
        if (copySuccess != constants.COPY_CLIPBOARD_STATES.NOT_COPIED) {
            const timeoutId = setTimeout(() => {
                setCopySuccess(constants.COPY_CLIPBOARD_STATES.NOT_COPIED)
            }, 3000);

            //Clearing so as to avoid memory leak
            return () => clearTimeout(timeoutId);
        }

    }, [copySuccess])

    async function copyToClipboard() {

        try {
            await navigator.clipboard.writeText(text);
            setCopySuccess(constants.COPY_CLIPBOARD_STATES.COPIED_SUCCESS);
        } catch (error) {
            setCopySuccess(constants.COPY_CLIPBOARD_STATES.COPIED_FAILED);
        }

    }

    return (

        <>
            {
                copySuccess === constants.COPY_CLIPBOARD_STATES.NOT_COPIED ? (
                    <FontAwesomeIcon onClick={copyToClipboard} title='Copy' icon={faCopy} />
                ) : (
                    <FontAwesomeIcon
                        title={copySuccess === constants.COPY_CLIPBOARD_STATES.COPIED_SUCCESS ? 'Successfully copied' : 'Failed to copy'}
                        icon={copySuccess === constants.COPY_CLIPBOARD_STATES.COPIED_SUCCESS ? faCheck : faTimes}
                    />
                )}
        </>

    );

}