import { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { ButtonLoader } from "../components";
import 'react-responsive-modal/styles.css';

export default function AppModal({isShow, setIsShow, title, body, modalSize, onSubmit}){
    
    const [buttonDisable, setButtonDisable] = useState(false);

    function onSubmitClick(){

        setButtonDisable(true);
        onSubmit();

    }

    useEffect( () => {

        if(!isShow) setButtonDisable(false);

    }, [isShow])

    return (<>
    
    <Modal 
        open={isShow} 
        onClose={() => setIsShow(false)} 
        center
        classNames={{
            overlay: 'customOverlay',
            modal: `customModal ${modalSize}`
        }}
    >
        <h4>{title}</h4>
        {body}
        <div className="flex-row display-flex">
            <button type="button" className='btn btn-dark' onClick={() => setIsShow(false)}>Cancel</button>&nbsp;&nbsp;
            <button type="button" className='btn btn-light' disabled={buttonDisable} onClick={onSubmitClick}>
                Submit
                &nbsp;
                {buttonDisable ? <ButtonLoader/>: <></>}
            </button>
        </div>
      </Modal>

    </>)

}