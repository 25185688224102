import { useState } from "react";
import { FormMessage, constants } from "../utils";
import { HttpRequest } from "../services";
import { ButtonLoader } from "../components";

const formMessage = new FormMessage();
const httpRequest = new HttpRequest();

export default function ContactUs() {

    const [submitDisable, setSubmitDisable] = useState(false);

    function validateForm(form){

        let isValid = true;

        if(!form.name.value.trim()){
            formMessage.showError("Name required");
            isValid = false;
        }
        else if(!form.email.value.trim()){
            formMessage.showError("Email required");
            isValid = false;
        }
        else if(!form.message.value.trim()){
            formMessage.showError("Message required");
            isValid = false;
        }

        return isValid;

    }

    async function onSubmit(e){

        try {
            
            e.preventDefault();
            setSubmitDisable(true);
            const form = e.currentTarget;
    
            if(validateForm(form)){
    
                const data = { name: form.name.value.trim(), message: form.message.value.trim(), email: form.email.value.trim() }
                const resp = await httpRequest.postData(constants.API_URL.CONTACT_US.CREATE, data);
                console.log("Resp: ", resp);

                if (resp.success) onSubmitSuccess(resp, form);
                else onError(resp);

            }

        } catch (error) {
            
            onError(error);

        }

    }


    function onSubmitSuccess(resp, form){

        formMessage.showSuccess(resp.message);
        form.reset();
        setSubmitDisable(false);

    }


    function onError(err){

        console.log(`Error: `, err);
        setSubmitDisable(false);
        formMessage.showError(err.message);

    }


    return (

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="contact-content">
                            <h2 className="medium-heading">Contact Us</h2>
                            <p>We are here to answer all your queries. Get in touch and the right team will get back to you as soon as possible to provide the assistance you need.</p>

                            <form onSubmit={onSubmit} className="contact-form">
                                <div className="contact-label">
                                    <label htmlFor="name">Name</label>
                                    <input id="name" maxLength={250} type="text" name="name" required />
                                </div>
                                <div className="contact-label">
                                    <label htmlFor="email">Email</label>
                                    <input id="email" maxLength={250} type="email" name="email" required />
                                </div>
                                <div className="contact-label">
                                    <label htmlFor="message">Message</label>
                                    <textarea id="message" maxLength={3000} name="message" required></textarea>
                                </div>
                                <button disabled={submitDisable}>Submit {submitDisable ? <ButtonLoader className="spinner" />: <></>}</button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )

}