import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Helpers } from "../utils";

library.add(faDownload);

export default function DownloadCode({ text, language }){

    const [downloading, setDownloading] = useState(false);

    function downloadCode(){

        setDownloading(true);

        const fileName = `${Helpers.generateRandomFileName()}${Helpers.getProgrammingLanguageExtension(language)}`;

        const blob = new Blob([text], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = fileName;
        link.href = url;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        const timeoutId = setTimeout( () =>  setDownloading(false), 4000 );
        //Clearing so as to avoid memory leak
        return () => clearTimeout(timeoutId);

    }

    return (
        <FontAwesomeIcon className={downloading ? 'disabled': ''} title='Download' onClick={downloadCode} icon={faDownload} />
    );

}