import { Link, useNavigate } from "react-router-dom";
import { constants, FormMessage } from "../utils";
import { RightArrowIcon } from "../components";
// import { HttpRequest } from "../services";
 
// const httpRequest = new HttpRequest();
// const formMessage = new FormMessage();

export default function Pricing() {

    const navigate = useNavigate();

    // async function checkoutPlan(e, plan_name){

    //     e.preventDefault();

    //     try {
         
    //         const SEND_AUTH_HEADER = true;
    
    //         const resp = await httpRequest.postData(constants.API_URL.PAYMENT.CHECKOUT, { plan_name }, SEND_AUTH_HEADER);
    //         if(resp.success) onCheckoutPlanSuccess(resp);
    //         else onError(resp);

    //     } catch (error) {

    //         onError(error);
            
    //     }
    
    // }

    // async function onCheckoutPlanSuccess(resp){

    //     if(resp?.data?.url){
    //         window.open(resp.data.url, '_blank');
    //     }else{
    //         onError({message: "Checkout url link not generated"});
    //     }

    // }


    // function onError(err){

    //     console.log(`Error:`, err);   
    //     if(err.error.statusCode === 400) navigate(constants.ROUTES.SIGN_IN);    
    //     else if(err.message) formMessage.showError(err.message);

    // }

    return (<>

        <section className="price-sec">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="about-content">
                            <h2 className="medium-heading">Pricing</h2>
                            <p>Code007 - Feature Plans:</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 my-4">
                        <div className="price-list-content price-list-content-br">
                            <h3>Trial Plan</h3>

                            <div className="price-value">
                                <span>$</span>7<p>/ month 14 days free</p>
                            </div>
                            <ul>
                                <li> Access to the Code007 LLM-powered AI Code creation and chatbot tool</li>
                                <li>Full functionality available for 14 days free trial</li>
                                <li>Limited features compared to other plans</li>
                                <li>Ability to create and test code with the assistance of AI-powered suggestions</li>
                                <li>Basic customer support</li>
                            </ul>

                            {/* <br/><br/>
                            <div className="text-center">
                                <Link onClick={e => checkoutPlan(e, 'plan_trial')} to={constants.ROUTES.SIGN_IN} className="button-small-bg ms-sm-3">Subscribe <RightArrowIcon/></Link>
                            </div> */}

                        </div>
                    </div>
                    <div className="col-lg-4 my-4">
                        <div className="price-list-content price-list-content-br move-up">
                            <span className="span-bg ">Recommended</span>
                            <h3>Basic Plan</h3>

                            <div className="price-value">
                                <span>$</span>12<p>/ month</p>
                            </div>
                            <ul>
                                <li> Access to the Code007 LLM-powered AI Code creation and chatbot tool</li>
                                <li>Full functionality available</li>
                                <li>Enhanced features compared to the Trial Plan</li>
                                <li>Access to advanced AI code generation capabilities</li>
                                <li>Priority customer support</li>
                            </ul>

                            {/* <br/><br/>
                            <div className="text-center">
                                <Link onClick={e => checkoutPlan(e, 'plan_basic')} to={constants.ROUTES.SIGN_IN} className="button-small-bg ms-sm-3">Subscribe <RightArrowIcon/></Link>
                            </div> */}

                        </div>
                    </div>
                    <div className="col-lg-4 my-4">
                        <div className="price-list-content price-list-content-br">
                            <h3>Pro Plan </h3>

                            <div className="price-value">
                                <span>$</span>29<p>/ month </p>
                            </div>
                            <ul>
                                <li> Access to the Code007 LLM-powered AI Code creation and chatbot tool</li>
                                <li>Full functionality available</li>
                                <li>All features included in the Basic Plan</li>
                                <li>Additional advanced features for professional developers</li>
                                <li>Enhanced AI code generation capabilities</li>
                                <li>Dedicated customer support with faster response times
                                </li>
                            </ul>

                            {/* <br/><br/>
                            <div className="text-center">
                                <Link onClick={e => checkoutPlan(e, 'plan_pro')} to={constants.ROUTES.SIGN_IN} className="button-small-bg ms-sm-3">Subscribe <RightArrowIcon/></Link>
                            </div> */}

                        </div>
                    </div>
                </div>

            </div>


        </section>

    </>)

}