const ROUTES = {
    HOME: '/dashboard',
    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    LANDING: '/',
    CODE_SHARE: '/dashboard/share/:id',
    CHAT_EXPERT: '/dashboard/chat/:id',
    SSO_AUTH_CALLBACK: '/sso/auth/callback',
    ABOUT_US: '/about-us',
    CONTACT_US: '/contact-us',
    TERMS_CONDITIONS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    PRICING: '/pricing'
}

const DOCUMENT_TITLES = {
    SIGN_IN: 'Sign In',
    SIGN_UP: 'Sign Up',
    LANDING: 'Landing',
    HOME: 'Home | Get Started',
    ABOUT_US: 'About Us',
    CONTACT_US: 'Contact Us'
}

const API_BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL_PROD : process.env.REACT_APP_API_BASE_URL_DEV;
const EXTERNAL_API_BASE_URL = process.env.REACT_APP_EXTERNAL_API_BASE_URL;

const API_URL = {
    SIGN_UP: '/api/v1/users/signup',
    SIGN_IN: '/api/v1/users/signin',
    GENERATE_OTP: '/api/v1/users/auth/mobile/otp',
    VERIFY_OTP: '/api/v1/users/auth/mobile/otp',
    EMAIL_VERIFY_OTP: '/api/v1/users/auth/email/otp',
    GET_CHATBOTS: '/api/v1/chatbots',
    HISTORY: {
        CREATE: '/api/v1/histories',
        GET_ALL: '/api/v1/histories',
        GET: '/api/v1/histories/:id',
        DELETE: '/api/v1/histories/:id'
    },
    CONTACT_US: {
        CREATE: '/api/v1/contact-us'
    },
    PAYMENT: {
        CHECKOUT: '/api/v1/payments/checkout'
    },
    CHATBOTS:{
        ASK: '/api/v1/chatbots/ask'
    }
}

const EXTERNAL_URL = {
    SSO_AUTH: `${API_BASE_URL}/api/v1/users/auth/sso`,
    SERVICE_LLM_V3: `${EXTERNAL_API_BASE_URL}/api/service/llm/v3`
}

const TOAST_TYPES = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning'   
}

const LOGIN_TYPES = {
    EMAIL: 'email',
    SSO: 'sso',
    MOBILE: 'mobile'
}

const HISTORY_TYPES = {
    CODE: 'code',
    GENERAL: 'general'
}

const PROMPT_TOGGLE_TYPES = {
    CODE: 'code',
    GENERAL: 'general'
}

const STATUS = {
    ACTIVE: 1,
    INACTIVE: 0
}

const API_TOKENS = {
    EXTERNAL: process.env.REACT_APP_EXTERNAL_API_TOKEN
}

const COPY_CLIPBOARD_STATES = {
    NOT_COPIED: 0,
    COPIED_SUCCESS: 1,
    COPIED_FAILED: -1
}

const PROGRAMMING_LANGUAGE_EXTENTIONS = {
    javascript: ".js",
    python: ".py",
    java: ".java",
    c: ".c",
    cpp: ".cpp",
    "c++": ".cpp",
    "c#": ".cs",
    ruby: ".rb",
    php: ".php",
    swift: ".swift",
    "objective-c": ".m",
    kotlin: ".kt",
    typescript: ".ts",
    go: ".go",
    perl: ".pl",
    rust: ".rs",
    scala: ".scala",
    haskell: ".hs",
    lua: ".lua",
    shell: ".sh",
    sql: ".sql",
    html: ".html",
    css: ".css"
};


const HEADER_DIV_ID_LABELS = {
	ABOUT: 'about-content',
	FOOTER: 'footer-content',
	PRICING: 'pricing-content'
}

const PROMPT = {
    FOLLOW_UP: {
        YES: 1, NO: 0
    }
}

const WEBSITE_DETIALS = {
    CONTACT_US: {
        EMAIL: 'contact@code007.com',
        PHONE: '+91 8178974297'
    }
}

export default { 
    TOAST_TYPES, 
    ROUTES, 
    DOCUMENT_TITLES, 
    API_BASE_URL, 
    API_URL,
    LOGIN_TYPES,
    EXTERNAL_URL,
    STATUS,
    EXTERNAL_API_BASE_URL,
    API_TOKENS,
    HISTORY_TYPES,
    PROMPT_TOGGLE_TYPES,
    COPY_CLIPBOARD_STATES,
    PROGRAMMING_LANGUAGE_EXTENTIONS,
    HEADER_DIV_ID_LABELS,
    PROMPT,
    WEBSITE_DETIALS
};