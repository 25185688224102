import React from "react";
import ReactMarkdown from "react-markdown";
import CodeGeneration from "./code-generation";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import { Helpers } from "../utils";

const Markdown = ({ markdown, language }) => {

  // markdown = "Sure, I can help you with that. Here is an example code snippet in JavaScript that demonstrates the distinctions between `let`, `const`, and `var`:\n\n```javascript\n// Using let:\nlet x = 10;\nx = 20; // Allowed to reassign the value\nconsole.log(x); // Output: 20\n\n// Using const:\nconst y = 30;\ny = 40; // Error: Cannot reassign a constant variable\nconsole.log(y); // Output: 30\n\n// Using var:\nvar z = 50;\nz = 60; // Allowed to reassign the value\nconsole.log(z); // Output: 60\n```\n\nIn this code, `let` is used to declare a variable that can be reassigned. `const` is used for constants, which cannot be reassigned after declaration. And `var` is the older way of declaring variables, which allows reassignment but has some scoping differences compared to `let` and `const`.\n\nLet me know if you need any further assistance!";

  return (

    <ReactMarkdown
      children={markdown}
      components={{
        code(props) {
          const { children, className, node, ...rest } = props
          const match = /language-(\w+)/.exec(className || '')
          return match ? (
            (<CodeGeneration
              language={language}
              text={String(children).replace(/\n$/, "")}
              {...props}
            />)
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          )
        }
      }}
    />

  );
};

export default Markdown;
