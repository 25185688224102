import { Code, cross1, S3, pythonCodeClip, ss4 } from "../assets/images";
import { Link } from "react-router-dom";
import { constants, Storage } from "../utils";
import { useEffect, useState, useLayoutEffect } from "react";
import { Logo } from "../assets/images";
import { Accordion, RightArrowIcon } from "../components";
import "../assets/css/landing-page.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const storage = new Storage();

const accordionDataGeneral = [
	{
		title: 'What level of coding expertise is required to use Code 007?',
		content: `Code 007 is designed to be user-friendly and does not require any prior coding experience.`
	},
	{
		title: 'Can Code 007 generate code for web applications?',
		content: `Yes, Code 007 can generate code for web applications using various web frameworks such as Flask, Django, and more.`
	},
	{
		title: 'How does Code 007 generate code?',
		content: `Code 007 uses LLM (Language Model) technology to analyze natural language inputs and generate high-quality code.`
	},{
		title: 'Is Code 007 compatible with Windows and macOS?',
		content: 'Yes, Code 007 is compatible with both Windows and macOS.'
	},
	{
		title: 'How does Code 007 compare to other code generators on the market?',
		content: 'Code 007 is powered by advanced LLM technology, providing more accurate and efficient code generation than other code generators.'
	}
];


const accordionDataPrivacy = [
	{
		title: 'Does Code 007 store any of my personal data?',
		content: `Code 007 only stores data necessary for generating code, and all personal data is encrypted and secured.`
	},
	{
		title: 'Can I trust Code 007 with sensitive code projects?',
		content: 'Yes, Code 007 prioritizes privacy and security, ensuring that all code generated is secure and protected.'
	},
	{
		title: 'What type of encryption does Code 007 use for data protection?',
		content: 'Code 007 uses industry-standard encryption protocols such as AES-256 to protect user data.'
	}
];

const accordionDataResponsibleAI = [
	{
		title: 'Does Code 007 have any biases in code generation?',
		content: `Code 007 is designed to be unbiased in code generation and ensures fairness and accuracy in all generated code.`
	},
	{
		title: 'How does Code 007 ensure ethical and responsible AI practices?',
		content: 'Code 007 adheres to ethical and responsible AI practices by prioritizing user privacy, avoiding biased code generation, and providing ongoing updates and support.'
	}
]

const accordionDataUpcomingFeaturesOfferings = [
	{
		title: 'What new features can we expect from Code 007 in the future?',
		content: `Code 007 is constantly evolving and improving, with upcoming features such as support for additional programming languages and enhanced code optimization.`
	},
	{
		title: 'How much does a subscription to Code 007 cost?',
		content: 'The cost of a subscription to Code 007 varies depending on the chosen plan, with options for individual and enterprise use.'
	},
	{
		title: 'Can I use Code 007 for commercial projects?',
		content: 'Yes, Code 007 can be used for commercial projects with the appropriate subscription plan.'
	},
	{
		title: 'Does Code 007 offer any collaboration features?',
		content: 'Yes, Code 007 offers collaboration features such as code sharing and version control for team projects.'
	},
	{
		title: 'Is there a limit to the amount of code that Code 007 can generate?',
		content: 'Code 007 can generate large amounts of code, with no set limit to the size or complexity of generated code.'
	}
]

export default function Landing() {

	const [faqActiveLink, setfaqActiveLink] = useState(0);

	useEffect(() => {

		AOS.init();

	}, []);

	useLayoutEffect(() => {

		document.body.classList.add(constants.DOCUMENT_TITLES.LANDING);
		return () => { document.body.classList.remove(constants.DOCUMENT_TITLES.LANDING) }

	}, []);

	return (<>

		<section>
			<div className="main-banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<p className="text-with-radius aos-init aos-animate" data-aos="fade-up">
								<img src={Logo} width={20} alt="Logo" />
								&nbsp; Code007
							</p>
							<h1 data-aos="fade-up">Code First, Think Later, Power Up Your Ideas with Code 007</h1>
							<p>Transform your ideas into executable code with the power of Generative AI!</p>
							<Link to={storage.isUserExists() ? constants.ROUTES.HOME : constants.ROUTES.SIGN_IN} className="button-small-bg ms-sm-3">Get started <RightArrowIcon/></Link>
						</div>
						<div className="col-md-12" data-aos="fade-up">
							<div className="lp-Hero-videoContainer">
								{/* <video autoPlay="" playsInline="" muted="" className="lp-Hero-video lp-Hero-video--landscape hide-reduced-motion" width="1248" height="735" poster="https://github.githubassets.com/assets/hero-poster-18f705106687.webp">
									<source src="https://github.githubassets.com/assets/hero-lg-6a98e47708e8.mp4" type="video/mp4; codecs=avc1.4d002a" />
								</video> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section>
			<div className="content-section " data-aos="fade-up" data-aos-duration="3000">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="content-head">
								<span className="small-heading">Code 007</span>
								<h2 className="medium-heading">Revolutionizing Programming with Gen AI-Powered Text-to-Code Technology</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-7">
							<div className="box-1 bg2 bg-overlay">
								<h3>Intuitive AI Integration</h3>
							</div>
						</div>
						<div className="col-md-5">
							<div className="box-2 bg3">
								<p>&nbsp;</p>
								<div className="box-text">
									<p>Seamlessly Converts Natural Language into Efficient Code, Streamlining Development Processes.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-5">
							<div className="box-2 bg4">
								<p className="w-100">&nbsp;</p>
								<div className="box-text">
									<h4>Advanced Learning Algorithms</h4>

								</div>
							</div>
						</div>
						<div className="col-md-7">
							<div className="box-1 bg5 bg-overlay">
								<h3>Code007 Adapts to Coding Styles and Preferences, Enhancing Personalization and Productivity.</h3>
							</div>
						</div>

					</div>

				</div>


				<div className="container"><div className="content-with-image " data-aos="fade-up">
					<div className="row">
						<div className="col-md-6">
							<div className="box-img-1">
								<div className="box-content-img-1">
									<h3>Cross-Language Support</h3>
									<p>Effortlessly Translate Concepts Across Multiple Programming Languages, Bridging the Gap in Software Development.</p>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="box-img">
								<img src={cross1} />
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
			<div className="content-section-2 bg-overlay">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2 className="medium-heading">How It Works</h2>
						</div>
						<div className="col-md-4">
							<div className="box-content">
								<h3>	Enter Your Query</h3>
								<p>Just type your requirement in natural language.</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="box-content">
								<h3>	AI Magic</h3>
								<p>Our AI analyzes your query and crafts optimized code.</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="box-content">
								<h3>	Code Delivery</h3>
								<p>Get well-structured, ready-to-use code in seconds.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="content-section " data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="content-head">
								<span className="small-heading">Features</span>
								<h2 className="medium-heading">Empowering Developers with Code 007: Real-Time Code Conversation, Suggestions, and Auto-Correction</h2>
							</div>
							<div className="features-video" aria-hidden="true">
								<img src={pythonCodeClip} alt="Python code" />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-7">
							<div className="feature-content-1">
								<h3>Code Conversation.</h3>

							</div>
						</div>
						<div className="col-md-5">
							<div className="feature-content-2">
								<p>Transform your ideas into code effortlessly with Code 007's intuitive natural language to code conversion.</p>
								<p>Enable greater collaboration. <span>Code 007's the newest member of your team. You can ask general programming questions or very specific ones about your codebase to get answers fast, learn your way around, explain a mysterious regex, or get suggestions on how to improve legacy code.</span>
								</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-5">
							<div className="feature-box-text">
								<span className="small-heading">Features</span>
								<h3>Real-Time Code Suggestion</h3>
								<p>Enhance your coding efficiency with Code 007's intelligent, context-aware real-time code suggestions.</p>
							</div>
						</div>
						<div className="col-md-7">
							<div className="feature-box-img">
							<img src={S3} />
							</div>
						</div>

					</div>
					<div className="row align-items-center  my-4">
						<div className="col-md-5">
							<div className="feature-box-text">
								<span className="small-heading">Features</span>
								<h3>Code Correction</h3>
								<p>Automatically detect and correct coding errors with Code 007, ensuring cleaner, more reliable code output.</p>
							</div>
						</div>
						<div className="col-md-7">
							<div className="feature-box-img">
								<img src={ss4} alt="image"/>
							</div>
						</div>

					</div>
					{/* <div className="row with-bg">
						<div className="col-md-6">
							<div className="box-1">
								<span className="small-heading">Features</span>
								<h3>Prefer bespoke? Fine-tune a private Code 007 for the precision enterprises demand.</h3>
								<Link to={constants.ROUTES.HOME}>Join the waitlist<svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="Primer_Brand__ExpandableArrow-module__ExpandableArrow-stem___g4mdy" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></Link>
							</div>
						</div>
						<div className="col-md-6">

						</div>
					</div> */}
					{/* <div className="row my-3 aos-init aos-animate" data-aos="fade-up">
						<div className="col-md-7">
							<div className="box-1 bg1">
								<h3>Ask for assistance right in your terminal.
								</h3>
								<Link to={constants.ROUTES.HOME}>Try Code 007 in the CLI
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></Link>
							</div>

						</div>
						<div className="col-md-5">
							<div className="box-2">
								<div className="box-text">
									<h4>Keep flying with your favorite editor.
									</h4>
								</div>
								<div className="bottom-icons">
									<div className="row">
										<div className="col-6">
											<Link to={constants.ROUTES.HOME}><img src={featuresBento2Vscode} alt="" /><p>VS Code</p></Link>
										</div>
										<div className="col-6">
											<Link to={constants.ROUTES.HOME}><img src={featuresBento2Meovim} alt="" /><p>Neovim</p></Link>
										</div>
										<div className="col-6">
											<Link to={constants.ROUTES.HOME}><img src={featuresBento2Visualstudio} alt="" /><p>Visual Studio</p></Link>
										</div>
										<div className="col-6">
											<Link to={constants.ROUTES.HOME}><img src={featuresBento2Jetbrains} alt="" /><p>JetBrains IDEs</p></Link>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div> */}
					<div className="row" data-aos="fade-up">
						<div className="col-md-12">
							<div className="box-bg"><img src={Code} alt="ffg" /></div>
						</div>
					</div>
				</div>
			</div>

			{/* <div id={constants.HEADER_DIV_ID_LABELS.PRICING} className="pricing-section" data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="content-head">
								<span className="small-heading">Pricing</span>
								<h2 className="medium-heading">Affordable Pricing Plans for Every Developer</h2>
								<p>Choose from flexible, cost-effective pricing options tailored to fit your development needs and budget.</p>
							</div>
						</div>
					</div>
					<div className="price-row">
						<div className="row">
							<div className="col-md-6">
								<div className="price-list-content price-list-content-br">
									<span>Recommended</span>
									<h3>Pro</h3>
									<p>Advanced features for professional developers.</p>

									<div className="price-value">
										<span>$</span>19<p>per user / month</p>
									</div>
									<ul>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{
											display: "inline-block",
											userSelect: "none",
											verticalAlign: "text-bottom",
											overflow: "visible"
										}}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg> Code completions</li>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{
											display: "inline-block",
											userSelect: "none",
											verticalAlign: "text-bottom",
											overflow: "visible"
										}}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg> Chat in IDE 1 and Mobile 2</li>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{
											display: "inline-block",
											userSelect: "none",
											verticalAlign: "text-bottom",
											overflow: "visible"
										}}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg> CLI assistance 3</li>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{
											display: "inline-block",
											userSelect: "none",
											verticalAlign: "text-bottom",
											overflow: "visible"
										}}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg>Security vulnerability filter
										</li>
									</ul>
									<div className="button-bottom">
										<button className="filled-btn">Buy Now <svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false" data-testid="Button-expandable-arrow"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></button>
										<button>Contact Sales
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false" data-testid="Button-expandable-arrow"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></button>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="price-list-content">
									<span>Available Feb 2024</span>
									<h3>Basic</h3>
									<p>Ideal for beginners.</p>

									<div className="price-value">
										<span>$</span>39<p>per user / month</p>
									</div>
									<ul>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{ "display": "inline-block", "userSelect": "none", "verticalAlign": "text-bottom", "overflow": "visible" }}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg> Code completions</li>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{ "display": "inline-block", "userSelect": "none", "verticalAlign": "text-bottom", "overflow": "visible" }}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg> Chat in IDE 1 and Mobile 2</li>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{ "display": "inline-block", "userSelect": "none", "verticalAlign": "text-bottom", "overflow": "visible" }}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg> CLI assistance 3</li>
										<li><svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 16 16" width="16" height="16" fill="currentColor" style={{ "display": "inline-block", "userSelect": "none", "verticalAlign": "text-bottom", "overflow": "visible" }}><path d="M13.78 4.22a.75.75 0 0 1 0 1.06l-7.25 7.25a.75.75 0 0 1-1.06 0L2.22 9.28a.751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018L6 10.94l6.72-6.72a.75.75 0 0 1 1.06 0Z"></path></svg>Security vulnerability filter
										</li>
									</ul>
									<div className="button-bottom">
										<button className="filled-btn">Buy Now <svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false" data-testid="Button-expandable-arrow"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></button>
										<button>Contact Sales
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false" data-testid="Button-expandable-arrow"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="content-head">
								<h2 className="medium-heading">For individuals</h2>
							</div>
						</div>
					</div>
					<div className="price-row free-price">
						<div className="row">
							<div className="col-md-6">
								<div className="price-list-content price-list-content-br">
									<span>Recommended</span>
									<h3>Free Trial</h3>
									<p>Explore our features with a 14-day free trial..</p>

									<div className="price-value">
										<span>$</span>10<p>per user / month</p>
									</div>


								</div>
							</div>
							<div className="col-md-6">
								<div className="price-list-content">
									<div className="button-bottom">
										<button className="filled-btn">Start a free trial<svg width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true" focusable="false" data-testid="Button-expandable-arrow"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></button>

									</div>
									<p>Free for verified students, teachers, and maintainers of popular open source projects.

									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

			{/* <div className="content-section-2 content-section-bottom " data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h3>Get the most out of Code 007 .</h3>
						</div>
						<div className="col-md-4">
							<div className="box-content">
								<h3>Hands-on consulting, guided workshops, and training.</h3>
								<p>Insights, best practices, and knowledge to help you adopt Code 007 quickly and efficiently.</p>
								<Link to={'#'}>Explore Code 007 Expert Services <svg className="Primer_Brand__ExpandableArrow-module__ExpandableArrow___rkfek Primer_Brand__Link-module__Link-arrow___HBMJ9" width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></Link>
							</div>
						</div>
						<div className="col-md-4">
							<div className="box-content">
								<h3>Meet the companies who build with Code 007.</h3>
								<p>Leading organizations choose Code 007 to plan, build, secure and ship software.</p>
								<Link to={"#"}>Explore Code 007 Expert Services <svg className="Primer_Brand__ExpandableArrow-module__ExpandableArrow___rkfek Primer_Brand__Link-module__Link-arrow___HBMJ9" width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></Link>
							</div>
						</div>
						<div className="col-md-4">
							<div className="box-content">
								<h3>Keep up with the latest on Code 007 and trends in AI.</h3>
								<p>Check out the Code 007 blog for tips, technical guides, best practices, and more.</p>
								<Link to={"#"}>Explore Code 007 Expert Services <svg className="Primer_Brand__ExpandableArrow-module__ExpandableArrow___rkfek Primer_Brand__Link-module__Link-arrow___HBMJ9" width="16" height="16" viewBox="0 0 16 16" fill="none" aria-hidden="true"><path fill="currentColor" d="M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z"></path><path className="arrow-l" stroke="currentColor" d="M1.75 8H11" strokeWidth="1.5" strokeLinecap="round"></path></svg></Link>
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</section>

		<section>
			<div className="tab-section " data-aos="fade-up">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="content-head">
								<h2 className="medium-heading">Frequently asked questions.</h2>
							</div>
							<div className="align-items-start">
								<div className="nav flex-column nav-pills col-md-12" id="v-pills-tab" role="tablist" aria-orientation="vertical">
									<button className={`nav-link ${faqActiveLink == 0 ? 'active' : ''}`} onClick={e => setfaqActiveLink(0)} id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">General</button>
									<button className={`nav-link ${faqActiveLink == 1 ? 'active' : ''}`} onClick={e => setfaqActiveLink(1)} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Privacy</button>
									<button className={`nav-link ${faqActiveLink == 2 ? 'active' : ''}`} onClick={e => setfaqActiveLink(2)} id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Responsible AI</button>
									<button className={`nav-link ${faqActiveLink == 3 ? 'active' : ''}`} onClick={e => setfaqActiveLink(3)} id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Upcoming features and offerings</button>
								</div>
								<div className="tab-content  col-md-12" id="v-pills-tabContent">
								<div className={`tab-pane fade ${faqActiveLink == 0 ? 'show active' : ''}`} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
										<h3>General</h3>
										<div className="accordion" id="accordionExample1">
											{
												accordionDataGeneral.map(({ title, content }, key) => (
													<Accordion key={`general-${key}`} dataKey={`general-${key}`} title={title} content={content} />
												))
											}
										</div>
									</div>
									<div className={`tab-pane fade ${faqActiveLink == 1 ? 'show active' : ''}`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
										<h3>Privacy</h3>
										<div className="accordion" id="accordionExample2">
											{
												accordionDataPrivacy.map(({ title, content }, key) => (
													<Accordion key={`privacy-${key}`} dataKey={`privacy-${key}`} title={title} content={content} />
												))
											}
										</div>
									</div>
									<div className={`tab-pane fade ${faqActiveLink == 2 ? 'show active' : ''}`} id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><h3>Responsible AI</h3>
										<div className="accordion" id="accordionExample3">
											{
												accordionDataResponsibleAI.map(({ title, content }, key) => (
													<Accordion key={`responsibleAI-${key}`} dataKey={`responsibleAI-${key}`} title={title} content={content} />
												))
											}
										</div>
									</div>
									<div className={`tab-pane fade ${faqActiveLink == 3 ? 'show active' : ''}`} id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab"><h3>Upcoming features and offerings</h3>
										<div className="accordion" id="accordionExample">
											{
												accordionDataUpcomingFeaturesOfferings.map(({ title, content }, key) => (
													<Accordion key={`upcomingFeatures&Offerings-${key}`} dataKey={`upcomingFeatures&Offerings-${key}`} title={title} content={content} />
												))
											}
										</div>
									</div>
								</div>
							</div>
							
							<br/>

						</div>
					</div>
				</div>

			</div>
		</section>

	</>)

}