import { useEffect, useState } from "react"
import { constants, FormMessage } from "../utils"
import { HttpRequest } from "../services";

const httpRequest = new HttpRequest();
const formMessage = new FormMessage();

export default function CodeHistory(){

    const [historyData, setHistoryData] = useState([]);

    useEffect( () => {

        getHistories();

    }, []);


    async function getHistories(){

        try {
            
            const data = { type: 'code', user_id: '659db4bf664a61de3a6e5f06' }
            const response = await httpRequest.getData(constants.API_URL.HISTORY.GET_ALL, data);
            console.log('Resp: ', response);

            if(response.success) onGetHistorySuccess(response);
            else onError(response);

        } catch (error) {
            
        }

    }


    function onGetHistorySuccess(resp){

        setHistoryData(resp.data);

    }


    function onError(error){
     
        console.log(`Error while submitting the form: ${error}`);
        formMessage.showError(error.message);

    }

    return (<>
    
        <aside className="w-64 shrink-0 py-6 px-4 h-full overflow-clip" id="sidebar">
            <div className="flex items-center gap-2 py-4">
                <div className="w-full h-[1px] bg-border"></div>
                <div className="text-xs uppercase tracking-wide text-gray-700 dark:text-gray-300 shrink-0 whitespace-nowrap font-medium">History</div>
                <div className="w-full h-[1px] bg-border"></div>
            </div>
            <div dir="ltr" className="relative overflow-hidden h-full" style={{"position": "relative", "--radix-scroll-area-corner-width": "0px", "--radix-scroll-area-corner-height": "0px"}}>
                <div data-radix-scroll-area-viewport="" className="h-full w-full rounded-[inherit]" style={{"overflow": "hidden scroll"}}>
                    <div style={{"minWidth": "100%", "display": "table"}}>
                        {
                            historyData.map( data => {
                                return (
                                    <a className="flex justify-between items-center px-2 py-2 rounded-lg gap-4 group" href={`/code/${data._id}`}>
                                        <p className="line-clamp-1 text-sm break-all">{data.prompt}</p>
                                    </a>
                                )
                            } )
                        }
                    </div>
                </div>
            </div>
        </aside>
    
    </>)

}