export default class Storage{

    constructor(){}

    setUser(data){
        this.setUserData(data);
        this.setUserToken(data);
    }

    setUserData(data){
        localStorage.setItem('user', JSON.stringify(data));
    }

    setUserToken(data){
        localStorage.setItem('user_token', data.token);
    }

    isUserExists(){
        return Boolean(localStorage.getItem('user_token'));
    }

    getUser(){
        return localStorage.getItem('user')  ? JSON.parse( localStorage.getItem('user') ) : null;
    }


    getUserProfile(){
        return this.getUser() ? this.getUser()?.profile_pic : '';
    }

    getUserToken(){
        return localStorage.getItem('user_token');
    }

    getUserId(){
        return this.getUser()?._id;
    }

    clearUser(){
        localStorage.removeItem('user_token');
        localStorage.removeItem('user');
    }

}