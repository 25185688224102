import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { constants, FormMessage, Storage } from "../utils";
import { ButtonLoader } from "../components";
import { HttpRequest } from "../services";
import { useNavigate, Link } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';

const formMessage = new FormMessage();
const httpRequest = new HttpRequest();
const storage = new Storage();

const SIGNIN_STAGE_OTP_GENERATE = 1;
const SIGNIN_STAGE_OTP_VERIFY = 2;

export default function SignUp(){

    const navigate = useNavigate();

    const [type, setType] = useState(constants.LOGIN_TYPES.EMAIL);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [buttonDisable, setButtonDisable] = useState(false);
    const [signStage, setSignStage] = useState(SIGNIN_STAGE_OTP_GENERATE);
    const [mobileEmailDisabled, setMobileEmailDisabled] = useState(false);

    useLayoutEffect(() => {

		document.body.classList.add('sign');
		return () => { document.body.classList.remove('sign') }

	}, []);


    useEffect( () => {

        const isMobileEmailDisabled = Boolean(signStage == SIGNIN_STAGE_OTP_VERIFY);
        setMobileEmailDisabled(isMobileEmailDisabled);

    }, [signStage]);

    
    function validateSignUpForm(){

        let isValid = true;

        if(!email.trim()){
            formMessage.showError("Email required");
            isValid = false;
        }
        else if(!password.trim()){
            formMessage.showError("Password required");
            isValid = false;
        }

        if(signStage == SIGNIN_STAGE_OTP_VERIFY){
            if(!otp.trim()){
                formMessage.showError("OTP required");
                isValid = false;                
            }
        }
        
        return isValid;

    }

    function onSignUp(e){

        e.preventDefault();
        if(type == constants.LOGIN_TYPES.EMAIL) onEmailSignUp(e);
        else if(type == constants.LOGIN_TYPES.MOBILE) onMobileSignUp(e);

    }

    async function onEmailSignUp(){

        setButtonDisable(true);
        try {
         
            if( validateSignUpForm() ){

                let data = { type, email: email.trim(), password: password.trim() }
            
                if(signStage == SIGNIN_STAGE_OTP_GENERATE){
                    var resp = await httpRequest.postData(constants.API_URL.SIGN_UP, data);
                }
                else if(signStage == SIGNIN_STAGE_OTP_VERIFY){
                    data = {...data, otp: Number(otp)}
                    var resp = await httpRequest.postData(constants.API_URL.EMAIL_VERIFY_OTP, data);
                }
                
                console.log("Resp: ", resp);
                
                if(resp.success) onSignUpSuccess(resp);
                else onError(resp);
            
            }else{

                setButtonDisable(false);

            }   

        } catch (error) {
            
            onError(error);

        }

    }


    function validateMobileSignUpForm(){

        let isValid = true;

        if(!mobile.trim()){
            formMessage.showError("Mobile number required");
            isValid = false;
        }

        if(signStage == SIGNIN_STAGE_OTP_VERIFY){
            if(!otp.trim()){
                formMessage.showError("OTP required");
                isValid = false;                
            }
        }

        return isValid;

    }


    async function onMobileSignUp(){
        
        setButtonDisable(true);

        try {
         
            if(validateMobileSignUpForm()){

                let data = { mobile: mobile.trim() }

                if(signStage == SIGNIN_STAGE_OTP_GENERATE){
                    var resp = await httpRequest.getData(constants.API_URL.GENERATE_OTP, data);
                }else if(signStage == SIGNIN_STAGE_OTP_VERIFY){
                    data = {...data, otp: otp.trim()};
                    var resp = await httpRequest.postData(constants.API_URL.VERIFY_OTP, data);
                }
                
                console.log("Resp: ", resp);
                if(resp.success) onMobileSignUpSuccess(resp);
                else onError(resp);

            }else{

                setButtonDisable(false);

            }

        } catch (error) {
            
            onError(error);

        }

    }

    function onMobileSignUpSuccess(resp){

        formMessage.showSuccess(resp.message);
        //When OTP is generated and sent to the user
        if(signStage == SIGNIN_STAGE_OTP_GENERATE){
            setButtonDisable(false);
            setSignStage(SIGNIN_STAGE_OTP_VERIFY);
        } 
        //When OTP is sent and to be verified
        else if(signStage == SIGNIN_STAGE_OTP_VERIFY){
            storage.setUser(resp.data);
            redirectOnSignUpSuccess();
        } 

    }

    function onSignUpSuccess(resp){

        formMessage.showSuccess(resp.message);
        //When OTP is generated and sent to the user
        if(signStage == SIGNIN_STAGE_OTP_GENERATE){
            setButtonDisable(false);
            setSignStage(SIGNIN_STAGE_OTP_VERIFY);
        } 
        //When OTP is sent and to be verified
        else if(signStage == SIGNIN_STAGE_OTP_VERIFY){
            storage.setUser(resp.data);
            redirectOnSignUpSuccess();
        } 

    }

    function redirectOnSignUpSuccess(){
        setTimeout( function(){
            navigate(constants.ROUTES.HOME);
        }, 1500);
    }

    function onError(error){

        setButtonDisable(false);
        console.log(`Error while submitting the form: ${error}`);
        formMessage.showError(error.message);

    }


    function submitButtonTxt(){

        let buttonTxt = '';
        if(type != constants.LOGIN_TYPES.SSO){

            switch (signStage) {

                case SIGNIN_STAGE_OTP_GENERATE:
                    buttonTxt = "Generate OTP";
                    break;
                case SIGNIN_STAGE_OTP_VERIFY:
                    buttonTxt = "Sign Up";
                    break;
                default:
                    buttonTxt = "";
    
            }

        }else{

            buttonTxt = "Sign Up";

        }

        return buttonTxt;

    }


    async function responseGoogleLoginMessage(resp){

        try {

            setType(constants.LOGIN_TYPES.SSO);
            console.log("Google Response login: ", resp);
            resp = {...resp, type: constants.LOGIN_TYPES.SSO}
            const response = await httpRequest.postData(constants.API_URL.SIGN_UP, resp);
            console.log("Resp: ", response);
            
            if(response.success) {
                storage.setUser(response.data);
                onSSOSignUpSuccess(response);
            }
            else {
                onError(response);
            } 
        
        } catch (error) {
        
            onError(error);
        
        }

    }

    function onSSOSignUpSuccess(resp){

        formMessage.showSuccess(resp.message);
        redirectOnSignUpSuccess();

    }

    return (<>
    
    <div className="outer-container text-center">

        <div className="content-part  animate__fadeInDown animate__animated">
            <div className="container-xl">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-body">
                            <h1 className="title-lg ">Coding Made Smarter</h1>
                            <form className="form-input" onSubmit={onSignUp}>
                                
                                <h5 className="text-white">Sign up to your Code007 account</h5>
                                <p className="text-muted">Please select a method to sign up.</p>

                                <div className="align-items-center">
  
                                    <div className="nav flex-column nav-pills col-md-12" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button className={`nav-link w-50 text-center ${type == constants.LOGIN_TYPES.MOBILE ? 'active': ''}`} onClick={e => setType(constants.LOGIN_TYPES.MOBILE)} id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Phone</button>
                                        <button className={`nav-link w-50 text-center ${type == constants.LOGIN_TYPES.EMAIL ? 'active': ''}`} onClick={e => setType(constants.LOGIN_TYPES.EMAIL)} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Email</button>
                                    </div>

                                </div>

                                {
                                        type == constants.LOGIN_TYPES.MOBILE
                                        ?
                                        <>    
                                            <input type="tel" pattern="[0-9]{10}" maxLength={12} placeholder="Enter mobile number..." value={mobile} onChange={e => setMobile(e.target.value)} disabled={mobileEmailDisabled} required />
                                            {
                                                signStage == SIGNIN_STAGE_OTP_VERIFY ? 
                                                <input type="number" minLength={6} maxLength={6} placeholder="Enter OTP" value={otp} onChange={e => setOtp(e.target.value)} required />
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                                {
                                        type == constants.LOGIN_TYPES.EMAIL ? 
                                        <>
                                            <input type="email" placeholder="Enter Email..." value={email} onChange={e => setEmail(e.target.value)} required disabled={mobileEmailDisabled} />
                                            <input type="password" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)} minLength={6} required disabled={mobileEmailDisabled} />
                                            {
                                                signStage == SIGNIN_STAGE_OTP_VERIFY ? 
                                                <input type="number" minLength={6} maxLength={6} placeholder="Enter OTP" value={otp} onChange={e => setOtp(e.target.value)} required />
                                                :
                                                <></>           
                                            }
                                        </>
                                        :
                                        <></>
                                }

                                <div className="col-md-12">
                                    <div className="login-buttons">
                                        <button className="login-btn" disabled={buttonDisable} type="submit">
                                            {submitButtonTxt()} 
                                            {buttonDisable? <ButtonLoader className="spinner"/> : <></>}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <span className="text-white"><br/>OR<br/></span>
        <div className="overflow-hidden">
            <div className="section bg-tile-top bg-neutral-600">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="login-buttons">
                                <GoogleLogin text={'signup_with'} size="large" width={384} onSuccess={responseGoogleLoginMessage} onError={onError} />
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <div className="text-muted">Already have an account ? <Link className="text-sky-500 font-medium" to={constants.ROUTES.SIGN_IN}>Sign In</Link></div>

            </div>
        </div>

    </div>
    
    </>);

}