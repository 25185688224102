import axios from "axios";
import { constants, Storage } from "../utils";

const storage = new Storage();

export default class HttpRequest{

    constructor(base_url = true){
        this.is_external = !base_url;
        this.base_url = base_url ? constants.API_BASE_URL : '';
        this.headers = {}
    }

    setAuthorization(){

        if(this.is_external){
            var authorizationHeader = constants.API_TOKENS.EXTERNAL
        }else{
            var authorizationHeader = `Bearer ${storage.getUserToken()}`;
        }

        this.headers = {...this.headers, Authorization: authorizationHeader }
        
    }

    async postData(api_endpoint, data, authorization = false){
        try {

            if(authorization) this.setAuthorization();
            const response = await axios.post(`${this.base_url}${api_endpoint}`, data, {headers: this.headers});
            console.log(response);
            return response.data;
            
        } catch (error) {

            return error.response.data;
    
        }
    
    }



    async getData(api_endpoint, data, authorization = false){

        try {

            if(authorization) this.setAuthorization();

            const response = await axios.get(`${this.base_url}${api_endpoint}`, { params: data, headers: this.headers });
            console.log(response);
            return response.data;
            
        } catch (error) {
 
            return error.response.data;
    
        }
    
    }


    async deleteData(api_endpoint, data, authorization = false){

        try {

            if(authorization) this.setAuthorization();

            const response = await axios.delete(`${this.base_url}${api_endpoint}`, { headers: this.headers });
            console.log(response);
            return response.data;
            
        } catch (error) {
 
            return error.response.data;
    
        }
    
    }


}