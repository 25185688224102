import { userDefault, logo24 } from "../assets/images";

export default function PromptResponseShimmerUI(){

    return (

        <>
            
            <div className="d-flex prompt-container">

                <div className="d-flex flex-column">

                    <img className="profile-icon" src={userDefault} alt="profile" width={24} height={24} />        

                </div>

                <div className="d-flex flex-column title-line-container">

                    <div className="shimmerBG title-label"></div>

                </div>

            </div>

            <hr/>
            
            <div className="d-flex prompt-container">

                <div className="d-flex flex-column">
        
                    <img className="profile-icon" src={logo24} alt="profile" width={24} height={24} />

                </div>

                <div className="d-flex flex-column title-line-container">

                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>
                    <div className="shimmerBG title-line"></div>

                </div>                

            </div>
        
        </>

    )

}