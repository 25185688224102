import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { constants } from "../utils";
import { useQuery } from 'react-query';
import { HttpRequest } from '../services';
import { PromptContext } from "../context/prompt";
import "../assets/css/sidebar.css";

const routeCodeShare = constants.ROUTES.CODE_SHARE;
const httpRequest = new HttpRequest();

export default function Sidebar({ isSidebarOpen, setIsSidebarOpen }){

    const { promptHistories, addPromptHistory } = useContext(PromptContext);
    //const { data: promptHistoriesData } = useQuery('promptHistories', fetchPromptHistories);

    useEffect( () => {

        fetchPromptHistories();

    }, []);

    const fetchPromptHistories = async () => {

        const SEND_AUTH_HEADER = true;
    
        try {
          const response = await httpRequest.getData(constants.API_URL.HISTORY.GET_ALL, {type: constants.HISTORY_TYPES.CODE}, SEND_AUTH_HEADER);
          addPromptHistory(response.data);
        } catch (error) {
          console.error('Error fetching prompt histories:', error);
        }
    
    }    

    return (

        <div className={`sidebar-code ${isSidebarOpen ? 'open': ''}`}>
            <ul>
                <li><Link reloadDocument to={constants.ROUTES.HOME}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>New Chat</Link></li>
            </ul>
            <div className="separator d-flex">
                <div className="line"></div>
                <h6 className="px-2 m-0">Recent Chats</h6>
                <div className="line"></div>
            </div>
            <div>
                <br/>
                <ul className="histories">
                    {
                        promptHistories && promptHistories.length 
                        ? 
                        promptHistories.map(doc => 
                            <li className="py-2" key={doc._id}>
                                <Link to={routeCodeShare.replace(':id', doc._id)}>
                                    {doc.prompt.length > 25 ? `${doc.prompt.substring(0, 25)}...` : doc.prompt}
                                </Link>
                            </li>    
                        )
                        :
                        <></>
                    }
                </ul>
            </div>
 
        </div>

    )

}