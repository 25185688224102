import { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { constants, FormMessage } from "../utils";
// import { useQuery } from 'react-query';
import { HttpRequest } from '../services';
import { PromptContext } from "../context/prompt";
import Trash from "./trash";
import AppModal from "./modal";
import NavbarClose from "./navbar-close";
import "../assets/css/sidebar.css";

const SEND_AUTH_HEADER = true;
const routeCodeShare = constants.ROUTES.CODE_SHARE;
const httpRequest = new HttpRequest();
const formMessage = new FormMessage();

export default function SidebarRight({ isHistoryOpen, setIsHistoryOpen }) {

    const deleteHistoryId = useRef();
    const navigate = useNavigate();
    const [showHistoryDeleteModal, setShowHistoryDeleteModal] = useState(false);
    const { promptHistories, addPromptHistory, removePromptHistory } = useContext(PromptContext);
    //const { data: promptHistoriesData } = useQuery('promptHistories', fetchPromptHistories);

    useEffect(() => {

        fetchPromptHistories();

    }, []);

    const fetchPromptHistories = async () => {

        try {
            const response = await httpRequest.getData(constants.API_URL.HISTORY.GET_ALL, { type: constants.HISTORY_TYPES.CODE }, SEND_AUTH_HEADER);
            addPromptHistory(response.data);
        } catch (error) {
            console.error('Error fetching prompt histories:', error);
        }

    }


    function deleteHistory(id) {

        deleteHistoryId.current = id;
        setShowHistoryDeleteModal(true);

    }


    async function onDeleteHistory() {

        try {

            if (!deleteHistoryId.current) throw new Error("Invalid delete history id");

            const deleteHistoryEndPoint = constants.API_URL.HISTORY.DELETE.replace(':id', deleteHistoryId.current);
            const response = await httpRequest.deleteData(deleteHistoryEndPoint, {}, SEND_AUTH_HEADER);

            if (response) onDeleteHistorySuccess(response, deleteHistoryId.current);

            setShowHistoryDeleteModal(false);
            deleteHistory.current = undefined;

        } catch (error) {

            onError(error);

        }

    }


    function onDeleteHistorySuccess(resp) {

        formMessage.showSuccess(resp.message);
        removePromptHistory(deleteHistoryId.current);
        navigate({ pathname: constants.ROUTES.HOME, search: "?reload=1" });

    }


    function onError(error) {

        console.error(`Error: ${error}`);
        formMessage.showError(error.message);

    }


    return (<>

        <div className={`sidebar-code-right ${isHistoryOpen ? 'open-right' : ''}`}>

            <div className="text-right"><span onClick={() => setIsHistoryOpen(false)}><NavbarClose/></span></div>

            <div class={`sidebar-overlay ${isHistoryOpen ? 'open-right' : ''}`}></div>

            <ul>
                <li><Link className="new-chat" reloadDocument to={constants.ROUTES.HOME}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>New Chat</Link></li>
            </ul>
            <div className="separator d-flex">
                <div className="line"></div>
                <h6 className="px-2 m-0">Recent Chats</h6>
                <div className="line"></div>
            </div>
            <div>
                <br />
                <ul className="histories">
                    {
                        promptHistories && promptHistories.length
                            ?
                            promptHistories.map(doc =>
                                <li className="p-2" key={doc._id}>
                                    <Link onClick={() => setIsHistoryOpen(false)} to={routeCodeShare.replace(':id', doc._id)}>
                                        {doc.prompt.length > 21 ? `${doc.prompt.substring(0, 21)}...` : doc.prompt}
                                    </Link>
                                    <span data-id={doc._id} title="Delete history" className="delete-history" onClick={() => deleteHistory(doc._id)}><Trash /></span>
                                </li>
                            )
                            :
                            <></>
                    }
                </ul>
            </div>

        </div>

        <AppModal isShow={showHistoryDeleteModal} setIsShow={setShowHistoryDeleteModal} title={'Delete Chat?'} body={<p>Are you sure you want to delete it?</p>} modalSize={'modal-sm'} onSubmit={onDeleteHistory} />

    </>)

}