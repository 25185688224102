import { Storage } from "../utils";
import { userDefault } from "../assets/images";

const storage = new Storage();

export default function UserProfile(){

    return (

        <img className="profile-icon" src={storage.getUserProfile() ?? userDefault} alt="profile" width={24} height={24} />        

    )

}