import { One, Two, Three, Four, Five } from "../assets/images";

export default function AboutUs() {

    return (

        <>

            <section className="about-us-container-section">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-content">
                                <h2 className="medium-heading">About Us</h2>
                                <p>Welcome to Code007, the revolutionary AI-powered code generator developed by the brilliant minds at Metadee. With the integration of Metalground AI's advanced API, Code007 is set to transform the way code is generated, making it faster, more efficient, and more creative than ever before.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="about-content">
                                <span className="small-heading">Our Vision</span>
                                <h3>Revolutionizing Code Generation</h3>
                                <p>At Code007, our vision is to revolutionize the world of code generation by leveraging the power of artificial intelligence and natural language processing. Our goal is to empower developers, businesses, and individuals to create high-quality, error-free, and scalable code with ease, regardless of their coding experience or expertise.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 order-img-2">
                            <div className="about-content-img">
                                <img src={One} alt="image" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5 order-img-2">
                            <div className="about-content-img">
                                <img src={Two} alt="image" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="about-content">
                                <span className="small-heading">The Power of Language AI</span>
                                <h3>Unleashing Your Coding Potential</h3>
                                <p>Code007 utilizes state-of-the-art language AI algorithms to unleash the full potential of your coding process. Our advanced models are trained on a vast corpus of data, enabling them to understand the context, generate relevant code, and provide valuable suggestions. Whether you need to write complex algorithms, create web applications, or develop software solutions, Code007 is your ultimate coding companion.

                                </p>
                            </div>
                        </div>

                    </div>

                    <div className="row align-items-center">

                        <div className="col-md-7">
                            <div className="about-content">
                                <span className="small-heading">Seamless Integration with Metalground AI</span>
                                <h3>Unmatched Capabilities</h3>
                                <p>As a proud member of the Metadee, Code007 leverages the API from Metalground AI, our trusted partner in delivering exceptional generative AI-driven solutions. The integration ensures that Code007 remains at the forefront of technological advancements, constantly evolving to meet the ever-changing needs of our users. Together, we strive to provide you with an unparalleled coding experience.

                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 order-img-2">
                            <div className="about-content-img">
                                <img src={Three} alt="image" />
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-md-5 order-img-2">
                            <div className="about-content-img">
                                <img src={Four} alt="image" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="about-content">
                                <span className="small-heading">Our Commitment</span>
                                <h3> Excellence and Innovation</h3>
                                <p>At Code007, we are committed to excellence and innovation. We continuously refine our algorithms, update our models, and incorporate user feedback to enhance the performance and capabilities of our platform. We believe in the power of collaboration and welcome suggestions from our users to make Code007 even more powerful, intuitive, and user-friendly.

                                </p>
                            </div>
                        </div>

                    </div>

                    <div className="row align-items-center">

                        <div className="col-md-7">
                            <div className="about-content">
                                <span className="small-heading">Join the Code007 Community</span>
                                <h3>Unlock Your Coding Potential</h3>
                                <p>Whether you are a seasoned developer, a business owner, or an individual with no coding experience, Code007 is here to unlock your coding potential. Say goodbye to tedious coding processes and costly errors. Experience the convenience, efficiency, and creativity that Code007 offers. Join the Code007 community today and witness the future of generative AI-powered code generation! Let your ideas take shape and watch as Code007 transforms them into functional, high-quality code that meets your needs. Together, let's redefine the way code is generated and shared.


                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 order-img-2">
                            <div className="about-content-img">
                                <img src={Five} alt="image" />
                            </div>
                        </div>
                    </div>


                </div>


            </section>

        </>

    )

}