import { constants } from "./index";

function replaceCharAtIndex(inputString, index, replaceString){

    if (index < 0 || index >= inputString.length) {
        // Index out of bounds, return the original string
        return inputString;
    }
    
      // Create a new string by combining the substring before the index,
      // the new character, and the substring after the index
    return (
        inputString.substring(0, index) +
        replaceString +
        inputString.substring(index + 1)
    );

}

function generateRandomFileName(){

    return (Math.random().toString(36).substring(2, 15) + Math.random().toString(23).substring(2, 5));

}

function getProgrammingLanguageExtension(language){

    return constants.PROGRAMMING_LANGUAGE_EXTENTIONS[language] ?? '.txt';

}


function getScrollYPercent() {
  
    var h = document.documentElement, 
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';
    
    const scrollPerc = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
  
    return Math.ceil(scrollPerc);
  
}


function jumpToReleventDiv(id) {
	const releventDiv = document.getElementById(id);
	if(releventDiv) releventDiv.scrollIntoView({behavior: "smooth"});
}

export default { replaceCharAtIndex, generateRandomFileName, getProgrammingLanguageExtension, getScrollYPercent, jumpToReleventDiv }