import React, { createContext, useState } from 'react';

const PromptContext = createContext();

const PromptProvider = ({ children }) => {
  
  const [promptHistories, setPromptHistories] = useState([]);

  const addPromptHistory = (history) => {

    setPromptHistories([...history, ...promptHistories]);
  
  }

  const removePromptHistory = history_id => {
  
    const updatedHistories = promptHistories.filter(val => val._id != history_id);
    setPromptHistories(updatedHistories);
  
  }

  return (
    <PromptContext.Provider value={{ promptHistories, addPromptHistory, removePromptHistory }}>
      {children}
    </PromptContext.Provider>
  );

};

export { PromptContext, PromptProvider };
