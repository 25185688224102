import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CopyToClipboard, DownloadCode } from './index';

export default function CodeGeneration({language, text}){

    return (<div className='position-relative'>
        <span className='code-options'>
            <DownloadCode text={text} language={language} /> &nbsp;
            <CopyToClipboard text={text} />
        </span>
        <SyntaxHighlighter
            className="code-syntax-container"
            language={language}
            style={a11yDark}
            PreTag="div"
            customStyle={{
                margin: 0,
                width: "100%",
                background: "transparent",
                padding: "1.5rem 1rem",
                fontFamily: "var(--font-geist-mono)",
              }}
              lineNumberStyle={{
                userSelect: "none",
              }}
              codeTagProps={{
                style: {
                  fontSize: "0.9rem",
                  fontFamily: "var(--font-mono)",
                },
              }}
        >{text}</SyntaxHighlighter>


    </div>);

}