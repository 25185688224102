import { Link } from "react-router-dom";
import { Logo } from "../assets/images";
import { constants } from "../utils";

export default function Footer() {

	return (<>

		<footer id="footer-content">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="footer-text">
							<h4 className="logo-footer"><img src={Logo} alt="Code007 logo" width={70} /></h4>
							<p>Pioneering the Future of Programming with Generative AI. We are dedicated to making coding accessible for all, from novices to seasoned developers.</p>
						</div>
					</div>
					<div id="about-content" className="col-md-2">
						<div className="menu">
							<h5>About</h5>
							<ul>
								<li><Link to={constants.ROUTES.ABOUT_US}>About</Link></li>
								<li><Link to={constants.ROUTES.CONTACT_US}>Contact Us</Link></li>
								<li><Link to={constants.ROUTES.PRICING}>Pricing</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-md-3">
						<div className="menu">
							<h5>Privacy</h5>
							<ul>
								<li><Link to={constants.ROUTES.PRIVACY_POLICY}>Privacy Policy</Link></li>
								<li><Link to={constants.ROUTES.TERMS_CONDITIONS}>Terms &amp; Conditions</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-md-3">
						<div className="menu">
							<h5>Contact Us</h5>
							<ul>
								<li><a href={`mailto:${constants.WEBSITE_DETIALS.CONTACT_US.EMAIL}`}>Email: {constants.WEBSITE_DETIALS.CONTACT_US.EMAIL}</a></li>
								<li><a href={`tel:${constants.WEBSITE_DETIALS.CONTACT_US.PHONE}`}>{constants.WEBSITE_DETIALS.CONTACT_US.PHONE}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom-footer">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<ul>
								<li>© {new Date().getFullYear()} Metadee AI, Coding Made Smarter.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>

	</>);

}