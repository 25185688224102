import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Logo } from "../assets/images";
import { constants, Storage, FormMessage, Helpers } from "../utils";
import NavbarClose from './navbar-close';

const storage = new Storage();
const formMessage = new FormMessage();

const scrollToReleventDiv = Helpers.jumpToReleventDiv;

export default function Header({isSidebarOpen, setIsSidebarOpen}) {

	const navigate = useNavigate();
	const location = useLocation();
	const [navbarOpen, setNavbarOpen] = useState(false);
	const [showSidebarButton, setShowSidebarButton] = useState(false);
	const [getStartedLink, setGetStartedLink] = useState(constants.ROUTES.SIGN_IN);

	useEffect(() => {

		if (location.hash) scrollToReleventDiv(location.hash.substring(1));

		if (location.pathname == constants.ROUTES.SIGN_UP) setGetStartedLink(constants.ROUTES.SIGN_IN);
		else if (location.pathname == constants.ROUTES.SIGN_IN) setGetStartedLink(constants.ROUTES.SIGN_UP);

		//Show sidebar button if route is /dashboard or /dashboard/*
		//setShowSidebarButton( location.pathname.match(constants.ROUTES.HOME) )

	}, [location]);


	async function logoutUser() {

		try {

			formMessage.showSuccess("Successfully logged out");
			setTimeout(() => {
				storage.clearUser();
				navigate(constants.ROUTES.LANDING);
			}, 500);

		} catch (error) {

			onError(error);

		}

	}

	function onError(error) {

		console.log(`Error while submitting the form: ${error}`);
		formMessage.showError(error.message);

	}

	return (<>

		<header>
			<div className="container-fluid">
				<div className="row py-3">
					<div className="col-3 text-start ">
						{ 
							// showSidebarButton 
							// &&  
							// <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="toggle-main">
							// 	<svg fill="#fff" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 7a1 1 0 100-2 1 1 0 000 2zm4.75-1.5a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zM5 12a1 1 0 11-2 0 1 1 0 012 0zm-1 7a1 1 0 100-2 1 1 0 000 2z" /></svg>
							// </button>	
						}
						<Link className="navbar-brand" to={constants.ROUTES.LANDING}><img src={Logo} width={70} alt="Code007 logo" />&nbsp;<span className='logo-text'>Code007</span></Link>
					</div>
					<div className="col-9 text-end display-flex">
						<nav className="navbar navbar-expand-md row align-items-center">

							<button className="navbar-toggler" onClick={() => setNavbarOpen(!navbarOpen)} type="button">
								<svg fill="#fff" width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 7a1 1 0 100-2 1 1 0 000 2zm4.75-1.5a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zm0 6a.75.75 0 000 1.5h11.5a.75.75 0 000-1.5H8.75zM5 12a1 1 0 11-2 0 1 1 0 012 0zm-1 7a1 1 0 100-2 1 1 0 000 2z"></path></svg>
							</button>
							<div className={`collapse navbar-collapse ${navbarOpen ? 'sidebar-open': ''}`} id="navbarNavDropdown">
								<span onClick={() => setNavbarOpen(false)}><NavbarClose/></span>
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link active" aria-current="page" to={constants.ROUTES.LANDING}>Home</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={constants.ROUTES.ABOUT_US}>About Us</Link>
									</li><li className="nav-item">
										<Link className="nav-link" to={constants.ROUTES.CONTACT_US}>Contact Us</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={constants.ROUTES.PRICING} onClick={e => scrollToReleventDiv(constants.HEADER_DIV_ID_LABELS.PRICING)}>Pricing</Link>
									</li>

									{
										storage.isUserExists() ?
											<>
												<li className="nav-item">
													<Link to={constants.ROUTES.HOME} className="nav-link">Dashboard</Link>
												</li>
												<li className="nav-item">
													<Link to={'#'} onClick={logoutUser} className="nav-link">Logout</Link>
												</li>
											</>
											:
											<></>
									}

								</ul>
							</div>

						</nav>
						<div className="right-menu">
							{
								storage.isUserExists() ?
									<></>
									:
									<>
										<Link to={getStartedLink} className="button-small-bg ms-sm-3">Get Started</Link>
										{/* <Link to={constants.ROUTES.SIGN_UP} className="button-small-bg ms-sm-3">Sign Up</Link>	 */}
									</>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="navbar-shadow" ></div>
		</header>

	</>);

}