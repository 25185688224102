import { useState, useRef, useEffect, useLayoutEffect, useContext } from "react";
import { HttpRequest } from "../services";
import { constants, FormMessage, Storage, Helpers } from "../utils";
import { Markdown, UserProfile, PromptResponseShimmerUI, CodeHistoryIcon } from "../components";
import { logo24, promptDownArrowIcon } from "../assets/images";
import { Link, useSearchParams, useOutletContext } from "react-router-dom";
import { PromptContext } from "../context/prompt";
import "../assets/css/code-page.css";

const formMessage = new FormMessage();
const httpRequest = new HttpRequest();
const externalHttpRequest = new HttpRequest(false);
const storage = new Storage();

const getScrollYOffset = Helpers.getScrollYPercent;
const scrollToReleventDiv = Helpers.jumpToReleventDiv;

const codeAIPromptQuestions = [
	{
		title: 'Python Function to Calculate Factorial of a Number',
		prompt: 'Write a Python function to calculate the factorial of a given number',
		language: 'Python'
	},
	{
		title: "Troubleshooting a 'NullPointerException' Error in Java: Tips and Techniques",
		prompt: "How can I troubleshoot a 'NullPointerException' error in Java? Explain with code examples",
		language: 'Java'
	},
	{
		title: "Understanding the Distinctions: let, const, and var",
		prompt: "What is the difference between let, const, and var?explain it with code",
		language: 'Javascript'
	}
]


const generalAIPromptQuestions = [
	{
		title: 'Introduction to Object-Oriented Programming (OOP)',
		prompt: 'Explain the concept of Object-Oriented Programming (OOP) and its key principles with code examples.'
	},
	{
		title: "Understanding Data Structures: Arrays vs Linked Lists",
		prompt: "Compare and contrast arrays and linked lists as data structures in programming. Provide code examples to illustrate their differences and use cases."
	},
	{
		title: "Implementing Binary Search Algorithm in Python",
		prompt: "Write a Python code snippet to implement the binary search algorithm and explain how it works step by step"
	}
]


export default function Home() {

	const [isHistoryOpen, setIsHistoryOpen] = useOutletContext();

	const [searchParams, setSearchParams] = useSearchParams();

	const { addPromptHistory } = useContext(PromptContext);

	const [toggleMode, setToggleMode] = useState(constants.PROMPT_TOGGLE_TYPES.CODE);
	const [onPromptAnswer, setOnPromptAnswer] = useState(false);
	const [prompt, setPrompt] = useState('');
	const [promptAnswer, setPromptAnswer] = useState('');
	const [buttonDisable, setButtonDisable] = useState(false);
	const promptQuestion = useRef('');
	const [chatbotsData, setChatbotsData] = useState([]);
	const [textAreaHeight, setTextAreaHeight] = useState(51);
	const [showScrollButton, setShowScrollButton] = useState(false);
	const [promptHistory, setPromptHistory] = useState([]);
	const [promptLisitngQuestions, setPromptListingQuestions] = useState([]);
	const lastPromptId = useRef('');
	const groupId = useRef('');
	const language = useRef('');
	const promptsCount = useRef(0);
	const followUpPrompt = useRef(constants.PROMPT.FOLLOW_UP.NO);

	useEffect(() => {

		getChatbotsData();
		addScrollToBottomFunction();

	}, []);

	function restoreInitialState(){

		setPromptHistory([]);
		setOnPromptAnswer(false);
		setSearchParams();
		promptsCount.current = 0;
		lastPromptId.current = '';
		groupId.current = '';
		language.current = '';
		followUpPrompt.current = constants.PROMPT.FOLLOW_UP.NO;

	}

	useEffect( () => {

		if( searchParams.get('reload') == 1) restoreInitialState();

	}, [searchParams]);

	useEffect(() => {

		//While generating a new prompt, scroll downwards to view it
		if (buttonDisable) scrollToReleventDiv(lastPromptId.current);

	}, [buttonDisable]);

	useLayoutEffect(() => {

		document.body.classList.add('home');
		return () => { document.body.classList.remove('home') }

	}, []);


	useEffect( () => {

		const promptQuestions = toggleMode == constants.PROMPT_TOGGLE_TYPES.CODE ? codeAIPromptQuestions : generalAIPromptQuestions;
		setPromptListingQuestions(promptQuestions);

	}, [toggleMode]);


	function addScrollToBottomFunction() {

		function handleScrollToBottomVisibility() {
			setShowScrollButton(Boolean(window.scrollY < 100));
		}

		window.addEventListener('scroll', handleScrollToBottomVisibility);

		return () => {
			window.removeEventListener('scroll', handleScrollToBottomVisibility);
		}

	}


	async function getChatbotsData() {

		const resp = await httpRequest.getData(constants.API_URL.GET_CHATBOTS, {}, true);
		console.log(resp);

		if (resp.success)
			setChatbotsData(resp.data);
		else
			onError(resp);

	}

	async function onPromptSubmit(e) {

		e.preventDefault();
		if (prompt) submitPrompt(prompt);

	}


	async function submitPromptFineTune(prompt_question, _language) {

		setPrompt(prompt_question);
		submitPrompt(prompt_question);
		language.current = _language;

	}

	async function submitPrompt(prompt_question) {

		setButtonDisable(true);

		const data = { q: prompt_question, group_id: groupId.current, followUp: followUpPrompt.current, limit: 0 }
		const resp = await httpRequest.postData(constants.API_URL.CHATBOTS.ASK, data, true);
		console.log("Resp: ", resp);

		if (resp.status == 1) onPromptSuccess(resp, prompt_question);
		else onError(resp);

	}

	function updateFollowupPrompt(){
		followUpPrompt.current = constants.PROMPT.FOLLOW_UP.YES;
	}

	function isFollowUpPrompt(){
		return Boolean(promptsCount.current > 1);
	}

	function onPromptSuccess(resp, prompt_question) {

		setPromptAnswer(resp.data)
		setOnPromptAnswer(true);
		promptQuestion.current = prompt_question;
		resp.question = promptQuestion.current;
		resp.language = language.current;
		setPromptHistory([...promptHistory, resp]);
		groupId.current = resp.group_id;
		setPrompt('');
		setButtonDisable(false);
		promptsCount.current++;
		savePromptAsHistory(resp);
		updateFollowupPrompt();
		lastPromptId.current = resp.uid;

	}

	function onError(error) {

		setButtonDisable(false);
		console.log(`Error while submitting the form: ${error}`);
		formMessage.showError(error.message);

	}


	async function savePromptAsHistory(resp) {

		try {

			const data = {
				user_id: storage.getUserId(),
				prompt: promptQuestion.current,
				type: toggleMode,
				content: resp.data,
				uid: resp.uid,
				regenerate_uid: resp.regenerate_uid,
				group_id: resp.group_id,
				language: language.current
			}

			const historySaveResp = await httpRequest.postData(constants.API_URL.HISTORY.CREATE, data, true);
			if(!isFollowUpPrompt() && historySaveResp && historySaveResp.data) addPromptHistory([{_id: historySaveResp.data.group_id, prompt: historySaveResp.data.prompt, createdAt: historySaveResp.data.createdAt}]);

		} catch (error) {

			throw error;

		}

	}


	function togglePrompt(e) {

		const toggleModeVal = e.target.getAttribute('datavalue');
		setToggleMode(toggleModeVal);

	}


	function onPromptChange(e) {

		setPrompt(e.target.value);

		const hiddenTextareaScrollHeight = document.getElementById('chat-input-box-hidden').scrollHeight;
		//console.log(e.target.scrollHeight, e.target.clientHeight, hiddenTextareaScrollHeight );

		if (hiddenTextareaScrollHeight != e.target.scrollHeight) setTextAreaHeight(hiddenTextareaScrollHeight)
		else if (hiddenTextareaScrollHeight < 51) setTextAreaHeight(51)
		else if (hiddenTextareaScrollHeight <= 222) setTextAreaHeight(e.target.scrollHeight)

	}


	function onPrompKeyDown(e) {

		if (e.keyCode == 13 && !e.shiftKey) onPromptSubmit(e);

	}


	function handleScrollToBottom() {

		window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

	}

	return (<>


			<div className="tab-html">
				<button onClick={() => setIsHistoryOpen(!isHistoryOpen)} className="history"><CodeHistoryIcon /></button>
				{
					promptHistory && promptHistory.length ? promptHistory.map(val => 
						<div key={val.uid}>
							<div className="d-flex prompt-container">
								<div className="d-flex flex-column">
									<UserProfile />
								</div>
								<div className="d-flex flex-column">
									<p>{val.question}</p>
								</div>
							</div>
							<hr />
							<div align="justify" className="d-flex prompt-container">
								<div className="d-flex flex-column">
									<img className="profile-icon" src={logo24} alt="profile" width={24} height={24} />
								</div>
								<div className="d-flex flex-column prompt">
									<Markdown language={val.language} markdown={val.data} />
								</div>
							</div>
							<hr />
							<div id={val.uid}></div>
							<br /><br /><br /><br />
						</div>
					): <></>
				}
				{
					buttonDisable && <PromptResponseShimmerUI />
				}
				{

					!buttonDisable && !onPromptAnswer
						?
						<>

							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<button className={`nav-link ${toggleMode == constants.PROMPT_TOGGLE_TYPES.CODE ? 'active' : ''}`} onClick={togglePrompt} datavalue={constants.PROMPT_TOGGLE_TYPES.CODE} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Code AI</button>
									<button className={`nav-link ${toggleMode == constants.PROMPT_TOGGLE_TYPES.GENERAL ? 'active' : ''}`} onClick={togglePrompt} datavalue={constants.PROMPT_TOGGLE_TYPES.GENERAL} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">General AI</button>
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">
								<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
									{
										toggleMode == constants.PROMPT_TOGGLE_TYPES.CODE ?
											<>
												<h1 className="mb-2 text-lg font-semibold">Select language for code generation</h1>
												<div className="chatbots-list">
													<div className="chatbots-list-inner">
														{
															chatbotsData.map((elem, key) => {
																const chatExpertRoute = constants.ROUTES.CHAT_EXPERT.replace(':id', elem.value);
																return (
																	<Link key={key} data-value={elem.label} to={chatExpertRoute} state={elem} className="chatbots-box pointer text-center">
																		<img width={52} src={elem.image} alt="Add chatbot" />
																		<h3 className="font-medium leading-none">{elem.label}</h3>
																	</Link>
																)
															})
														}
													</div>
												</div>
											</>
											:
											<></>
									}
									<h1 className="mb-2 text-lg font-semibold">Try These</h1>
									<div className="items-list">
										{
											promptLisitngQuestions.map(function (data, idx) {

												return (
													<div key={`prompt-fine-tune-${idx}`} className="items-box" onClick={e => submitPromptFineTune(data.title, data.language)}>
														<div className="items-inner">
															<h3>{data.title}</h3>
															<p>{data.prompt}</p>
														</div>
														<button className="next-btn">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path style={{ "fill": "#232326" }} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg>
														</button>
													</div>
												)

											})
										}
									</div>
								</div>
							</div>
						</>
						:
						<></>
				}

<div className="bottom-form">
				<form onSubmit={onPromptSubmit}>
					<div className="col-md-12 position-relative invisible"><textarea className="col-md-12" rows={1} id="chat-input-box-hidden" defaultValue={prompt}></textarea></div>
					{showScrollButton && <div className="poisition-relative text-center">
						<button type="button" onClick={handleScrollToBottom} title="Scroll to bottom" className="p-0 border-0 bg-transparent rounded-circle my-3">
							<img alt="down arrow icon" src={promptDownArrowIcon} />
						</button>
					</div>
					}
					<div className="inner-form position-relative">
						<textarea tabIndex="0" rows="1" placeholder=" Question..." value={prompt} onChange={onPromptChange} onKeyDown={onPrompKeyDown} spellCheck="false" id="chat-input-box" style={{ height: `${textAreaHeight}px` }}></textarea>
						<div className="right-btn position-absolute end-0">
							<button type="submit" disabled={!prompt || buttonDisable}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-white dark:text-black"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
							</button>
						</div>
					</div>
					{/* <p>By using Code007 you agree to the <a href="#">Terms <svg aria-hidden="true" height="7" viewBox="0 0 6 6" width="7" className="opacity-70"><path d="M1.25215 5.54731L0.622742 4.9179L3.78169 1.75597H1.3834L1.38936 0.890915H5.27615V4.78069H4.40513L4.41109 2.38538L1.25215 5.54731Z" fill="currentColor"></path></svg></a> and <a href="#">Privacy <svg aria-hidden="true" height="7" viewBox="0 0 6 6" width="7" className="opacity-70"><path d="M1.25215 5.54731L0.622742 4.9179L3.78169 1.75597H1.3834L1.38936 0.890915H5.27615V4.78069H4.40513L4.41109 2.38538L1.25215 5.54731Z" fill="currentColor"></path></svg></a>.</p> */}
				</form>
			</div>

			</div>

	</>);

}