import React, { useState } from 'react';

const Accordion = ({ title, content, dataKey }) => {

    const [isActive, setIsActive] = useState(false);

    return (

        <div key={dataKey} className="accordion-item">
            <h2 className="accordion-header" id={`heading${dataKey}`}>
                <button className={`accordion-button ${isActive ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#heading${dataKey}`} aria-expanded="true" aria-controls={`collapse${dataKey}`} onClick={() => setIsActive(!isActive) }>
                    {title}
                </button>
            </h2>
            <div id={`collapse${dataKey}`} className={`accordion-collapse collapse ${isActive ? 'show': ''}`} aria-labelledby={`heading${dataKey}`} data-bs-parent="#accordionExample">
                <div className="accordion-body">
                    <p>{content}</p>
                </div>
            </div>
        </div>

    );

};

export default Accordion;