export default function ButtonLoader({ className }){
    
    return (
      <span className={className}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 200 200" color="#000" fill="none">
  <defs>
    <linearGradient id="spinner-secondHalf">
      <stop offset="0%" stopOpacity="0" stopColor="currentColor"></stop>
      <stop offset="100%" stopOpacity="0.5" stopColor="currentColor"></stop>
    </linearGradient>
    <linearGradient id="spinner-firstHalf">
      <stop offset="0%" stopOpacity="1" stopColor="currentColor"></stop>
      <stop offset="100%" stopOpacity="0.5" stopColor="currentColor"></stop>
    </linearGradient>
  </defs>

  <g strokeWidth="8">
    <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100"></path>
    <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100"></path>
    <path stroke="currentColor" strokeLinecap="round" d="M 4 100 A 96 96 0 0 1 4 98"></path>
  </g>

  <animateTransform from="0 0 0" to="360 0 0" attributeName="transform" type="rotate" repeatCount="indefinite" dur="1300ms"></animateTransform>
</svg>
      </span>
  );

}