import React, { useState, useRef, useLayoutEffect, useEffect, useContext } from "react";
import { useParams, useLocation, useOutletContext } from "react-router-dom";
import { HttpRequest } from "../services";
import { constants, FormMessage, Storage, Helpers } from "../utils";
import { Markdown, UserProfile, PromptResponseShimmerUI, CodeHistoryIcon } from "../components";
import { logo24, promptDownArrowIcon } from "../assets/images";
import { PromptContext } from "../context/prompt";

const formMessage = new FormMessage();
const httpRequest = new HttpRequest();
const externalHttpRequest = new HttpRequest(false);
const storage = new Storage();

const getScrollYOffset = Helpers.getScrollYPercent;
const scrollToReleventDiv = Helpers.jumpToReleventDiv;

function getPromptText(prompt, language){
	return `You are a professional code writer tasked with writing codes for various purposes which are mentioned ahead. You only write codes and follow a proper code, that should be correct lexically, semantically, and syntactically correct(markdown format =\n              code_here\n              ). If the coding language is not specified ahead please use ${language} as the default language to write codes in. The following is the topic for the code to be written:\n              \"${prompt}\"`;
}

export default function ChatExpert() {

  const [isHistoryOpen, setIsHistoryOpen] = useOutletContext();
  const { addPromptHistory } = useContext(PromptContext);

  const { state } = useLocation();
  const { id } = useParams();
  const language = id.split("expert-").pop();
  
  const [onPromptAnswer, setOnPromptAnswer] = useState(false);
  const [promptAnswer, setPromptAnswer] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState(51);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [promptHistory, setPromptHistory] = useState([]);
  const lastPromptId = useRef("");
  const promptQuestion = useRef("");
  const groupId = useRef("");
  const promptsCount = useRef(0);
  const followUpPrompt = useRef(constants.PROMPT.FOLLOW_UP.NO);

  useLayoutEffect(() => {

    addScrollToBottomFunction();

		document.body.classList.add('home');
		return () => { document.body.classList.remove('home') }

	}, []);


  useEffect( () => {

    //While generating a new prompt, scroll downwards to view it
    if(buttonDisable) scrollToReleventDiv(lastPromptId.current);

  }, [buttonDisable]);


  function addScrollToBottomFunction(){

		function handleScrollToBottomVisibility(){
			setShowScrollButton( Boolean(getScrollYOffset() < 90) );
		}

		window.addEventListener('scroll', handleScrollToBottomVisibility);

		return () => {
			window.removeEventListener('scroll', handleScrollToBottomVisibility);
		}

	}


  function handleScrollToBottom(){

		window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});

	}

  const onPromptSubmit = async (e) => {
    
    e.preventDefault();

    if(!prompt) return;
    setButtonDisable(true);
 
    try {
      // Read the form data
      const form = e.target;
      // const formData = new FormData(form);
      // const prompt = formData.get('chat-input-box').trim();
      promptQuestion.current = prompt;
      const question = getPromptText(prompt, language);
      const data = { q: question, group_id: groupId.current, followUp: followUpPrompt.current, limit: 0 };
      const resp = await httpRequest.postData(constants.API_URL.CHATBOTS.ASK, data, true);
      
      if (resp.status == 1)
        onPromptSuccess(form, resp);
      else 
        onError(resp);
      
    } catch (error) {
      onError(error);
    }
  };

	function updateFollowupPrompt(){
		followUpPrompt.current = constants.PROMPT.FOLLOW_UP.YES;
	}

	function isFollowUpPrompt(){
		return Boolean(promptsCount.current > 1);
	}

  const onPromptSuccess = (form, resp) => {
    setPromptAnswer(resp.data);
    setOnPromptAnswer(true);
    resp.question = promptQuestion.current;
    setPromptHistory([...promptHistory, resp]);
    groupId.current = resp.group_id;
    setButtonDisable(false);
    promptsCount.current++;
    savePromptAsHistory(resp);
    updateFollowupPrompt();
    //form.reset();
    setPrompt("");
    lastPromptId.current = resp.uid;
  }

  const onError = (error) => {
    setButtonDisable(false);
    console.error(`Error while submitting the form: ${error}`);
    formMessage.showError(error.message);
  };

  const savePromptAsHistory = async(resp) => {
    
    const data = {
      user_id: storage.getUserId(),
      prompt: prompt,
      type: constants.PROMPT_TOGGLE_TYPES.CODE,
      content: resp.data,
      uid: resp.uid,
      regenerate_uid: resp.regenerate_uid,
      group_id: resp.group_id,
      language
    }

    try {
      const historySaveResp = await httpRequest.postData(constants.API_URL.HISTORY.CREATE, data, true);
      if(!isFollowUpPrompt() && historySaveResp && historySaveResp.data) addPromptHistory([{_id: historySaveResp.data.group_id, prompt: historySaveResp.data.prompt, createdAt: historySaveResp.data.createdAt}]);
    } catch (error) {
      console.error(error);
    }

  }

  function onPromptChange(e){

		setPrompt(e.target.value);

		const hiddenTextareaScrollHeight = document.getElementById('chat-input-box-hidden').scrollHeight;

		if(hiddenTextareaScrollHeight != e.target.scrollHeight) setTextAreaHeight(hiddenTextareaScrollHeight)
		else if(hiddenTextareaScrollHeight < 51) setTextAreaHeight(51)
		else if(hiddenTextareaScrollHeight <= 222) setTextAreaHeight(e.target.scrollHeight)

	}


  function onPrompKeyDown(e){

		if(e.keyCode == 13 && !e.shiftKey) onPromptSubmit(e);

	}

  if(!state || !language){
    return (<span>Not Found</span>) 
  }

  return (
    <>
      <div className="tab-html">
        
        <button onClick={() => setIsHistoryOpen(!isHistoryOpen)} className="history"><CodeHistoryIcon/></button>

        {promptHistory && promptHistory.length > 0 ? promptHistory.map( val => 
          <div key={val.uid}>
            <div className="d-flex prompt-container">
              <div className="d-flex flex-column">
                <UserProfile/>
              </div>
              <div className="d-flex flex-column">
                <p>{val.question}</p>
              </div>
            </div>
            <hr />
            <div align="justify"  className="d-flex prompt-container">
              <div className="d-flex flex-column">
                <img className="profile-icon" src={logo24} alt="profile" width={24} height={24} />  
              </div>
              <div className="d-flex flex-column prompt">
                <Markdown language={val.language} markdown={val.data} />
              </div>
            </div>
            <hr/>
            <div id={val.uid}></div>
            <br/><br/><br/><br/>
          </div>
        ): <></>}
        
        {buttonDisable && <PromptResponseShimmerUI/> }
        
        {!buttonDisable && !onPromptAnswer && (
          <div className="text-center">
            <img src={state.image} alt="Chatbot" />
            <br />
            <h1 className="mb-2 text-lg text-center">Explore {state.label} with our Chatbot!</h1>
            <p className="mb-2 leading-normal text-muted-foreground text-sm text-center">
              Hello there! I'm here to answer your {state.value}-related queries. Feel free to ask anything!
            </p>
          </div>
        )}

      </div>

      <div className="bottom-form">
        <form onSubmit={onPromptSubmit}>
          <div className="col-md-12 position-relative invisible"><textarea className="col-md-12" rows={1} id="chat-input-box-hidden" defaultValue={prompt}></textarea></div>
          {showScrollButton && <div className="poisition-relative text-center">
									<button type="button" onClick={handleScrollToBottom} title="Scroll to bottom" className="p-0 border-0 bg-transparent rounded-circle my-3">
										<img alt="down arrow icon" src={promptDownArrowIcon} />
									</button>
								</div>
					}
          <div className="inner-form">
            <textarea
              tabIndex="0"
              rows="1"
              placeholder="Ask Any Question..."
              spellCheck="false"
              id="chat-input-box"
              name="chat-input-box"
              value={prompt}
              onChange={onPromptChange}
              onKeyDown={onPrompKeyDown}
              style={{height: `${textAreaHeight}px`}}
            />
            <div className="right-btn position-absolute end-0">
								<button type="submit" disabled={!prompt || buttonDisable}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-white dark:text-black"><path d="M7 11L12 6L17 11M12 18V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
								</button>
						</div>
          </div>
        </form>
      </div>

    </>
  );
}
