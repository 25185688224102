import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";
import { constants } from "../utils";

export default function ErrorPage() {
    
    const error = useRouteError();
    
    return (
        <div>
            <p style={{ color: "red", fontSize: "30px" }}>
                {error?.status == "404" ? "404 Page Not Found" : ""}
            </p>
            <p>&lt; <Link to={constants.ROUTES.LANDING}>Back to Home</Link></p>
        </div>
    );
    
}
